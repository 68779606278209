<template>
  <div class="finq-spa-loader">
    <svg xmlns="http://www.w3.org/2000/svg" width="352" height="128" viewBox="0 0 352 352">
      <path
        class="finq-spa-svg"
        fill="#222222"
        stroke="#222222"
        d="M166.172 302.665c-33.223 0-61.995-5.03-85.546-14.953-17.459-28.682-27.065-69.285-27.065-114.446 0-43.771 8.048-81.45 23.278-109.03 25.168-10.656 56.037-16.286 89.333-16.286 33.673 0 62.436 5.022 85.512 14.926 15.53 27.318 23.734 65.463 23.734 110.39 0 48.383-7.829 86.885-23.269 114.467-23.532 9.909-52.451 14.932-85.977 14.932zm93.626-13.408c45.256-21.701 69.194-61.65 69.194-115.991 0-53.966-25.578-93.369-73.993-113.997C238.81 32.068 210.344 0 166.172 0 119.392 0 90 32.826 73.492 60.654 26.092 81.973 0 121.937 0 173.266c0 55.662 26.752 96.48 77.368 118.084 16.898 27.403 45.928 59.722 88.804 59.722 44.248 0 72.055-30.526 87.968-57.416 31.045 33.655 91.912 56.335 94.739 57.288L352 352v-62.743h-92.202z"
      ></path>
    </svg>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.finq-spa-svg {
  animation:
    animate-spa-svg-stroke 5s cubic-bezier(0.19, 1, 0.22, 1) 0s both infinite,
    animate-spa-svg-fill 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both infinite;
}
@keyframes animate-spa-svg-stroke {
  0% {
    stroke-dashoffset: 2121.74267578125px;
    stroke-dasharray: 2121.74267578125px;
  }

  25% {
    stroke-dashoffset: 4243.4853515625px;
    stroke-dasharray: 2121.74267578125px;
  }

  77% {
    stroke-dashoffset: 4243.4853515625px;
    stroke-dasharray: 2121.74267578125px;
  }

  100% {
    stroke-dashoffset: 6365.22802734375px;
    stroke-dasharray: 2121.74267578125px;
  }
}
@keyframes animate-spa-svg-fill {
  0% {
    fill: transparent;
  }

  7% {
    fill: transparent;
  }

  35% {
    fill: rgb(0, 0, 0);
  }

  70% {
    fill: rgb(0, 0, 0);
  }

  80% {
    fill: transparent;
  }

  100% {
    fill: transparent;
  }
}
</style>
